@font-face {
  font-family: 'Circe';
  src: url('./assets/fonts/circe-regular-webfont.eot');
  src: url('./assets/fonts/circe-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/circe-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/circe-regular-webfont.woff') format('woff'),
  url('./assets/fonts/circe-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  color: #001D23;
  font-family: Circe, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
